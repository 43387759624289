import "./App.css";
import React from "react";
import Menu from "./components/menu/menu";
import { BrowserRouter } from "react-router-dom";
import createRoutes from "./routes/router";
import ReactGA from "react-ga4";
import { createBrowserHistory } from "history";

const config = require("./config.json");

function App() {
    const history = createBrowserHistory();
    //initialise google analytics
    ReactGA.initialize(config.GAmeasurementID);
   
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search })
    return (
        <div className="App">
            <BrowserRouter history={history}>
                <Menu />

                {createRoutes()}
            </BrowserRouter>
        </div>
    );
}

export default App;
