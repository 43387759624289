import React from "react";
import Header from "../../components/header/header";
import Filters from "../../components/searchbar/filters/filters";
import Footer from "../../components/footer/footer";

import truck from "../../assets/truckorange.svg";
import boat from "../../assets/boatOrange.svg";
import time from "../../assets/transittimeOrange.svg";
import packages from "../../assets/packagesOrange.svg";
import delivery from "../../assets/deliveryOrange.svg";
import maps from "../../assets/mapsOrange.svg";

export default function carrierDirectory() {
    return (
        <>
            <Header />
            <main>
                <Filters />
                <div className="description-container">
                    <p>
                        Find different partners for different transportation
                        needs.
                    </p>
                    <div className="item-container">
                        <div className="item">
                            <img src={truck} alt="" />
                            <p>Express Service</p>
                        </div>
                        <div className="item">
                            <img src={boat} alt="" />
                            <p>Air and Ocean Services</p>
                        </div>
                        <div className="item">
                            <img src={time} alt="" />
                            <p>Expedited deliveries</p>
                        </div>
                        <div className="item">
                            <img src={packages} alt="" />
                            <p>Specialized Equipment</p>
                        </div>
                        <div className="item">
                            <img src={delivery} alt="" />
                            <p>Track and Trace technology</p>
                        </div>
                        <div className="item">
                            <img src={maps} alt="" />
                            <p>International Reach</p>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
}
