import React, { useState } from "react";
import arrow from "../../../assets/icons/arrowup-blue.svg";

export default function Filter({ title, list, setFilterFunction, selected }) {
    const [dropdownState, setDropdownState] = useState(false);

    // handle custom dropdown on tab enter
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            toggleList();
        }
    };
    // handle toggle of dropdown
    function toggleList() {
        setDropdownState(!dropdownState);
    }
    const listItems = list.map((item) => {
        return (
            <li key={item.code} className="filter-option">
                <div className="check-container">
                    <label htmlFor={item.code}>
                        <input
                            type="checkbox"
                            id={item.code}
                            value={item.code}
                            onChange={setFilterFunction}
                        />
                        <span className="checkbox"></span>
                        {item.title}
                    </label>
                </div>
            </li>
        );
    });
    return (
        <>
            <div className="filter-container">
                <div
                    className="filter-input"
                    onClick={toggleList}
                    tabIndex="0"
                    onKeyDown={handleKeyDown}
                    style={{
                        borderRadius: dropdownState && "5px 5px 0 0",
                        border: dropdownState && "solid 1px #29519D",
                    }}
                >
                    {title} {selected.length >= 1 && `(${selected.length})`}
                    <img
                        src={arrow}
                        alt=""
                        style={{
                            transform: !dropdownState && "rotate(180deg)",
                        }}
                    />
                </div>
                <ul
                    className="filter-options"
                    style={{
                        display: dropdownState ? "block" : "none",
                        border: dropdownState && "solid 1px #29519D",
                        borderTop: dropdownState && "none",
                    }}
                >
                    {listItems}
                </ul>
            </div>
            {dropdownState && (
                <div className="close-list" onClick={toggleList}></div>
            )}
        </>
    );
}
