import React from "react";
import miniLogo from "../../assets/Logo-E4P.svg";

export default function footer() {
    return (
        <footer>
            <div className="banner"></div>
            <div className="register-container">
                <div className="title">
                    <img src={miniLogo} alt="logo" />
                    <h3>What is Easy4Pro?</h3>
                </div>
                <p>
                    It is a digital platform that connects shippers and their
                    selected carriers all around the world in an easy and
                    transparent way.
                    <br />
                    <br />
                    Set up transport modes, invited carriers, budget thresholds,
                    validation flows, track and trace. If you need more we can
                    propose to you our services of the control tower and invoice
                    centralization.
                </p>
                <div className="button-container">
                    <button className="button-secondary" type="button">
                        <a
                            href="https://www.linkedin.com/company/easy4pro/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Follow us on Linkedin
                        </a>
                    </button>
                    <button className="button-secondary" type="button">
                        <a
                            href="https://www.easy4pro.com"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Visit Easy4Pro website
                        </a>
                    </button>
                    <button className="button-primary" type="button">
                        <a
                            href="https://www.easy4pro.com/contact"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Contact Us
                        </a>
                    </button>
                </div>
            </div>
            <div className="copyright">
                <p>
                    © 2021 - <span style={{ color: "#EC6825" }}>Easy</span>
                    <span style={{ color: "#29519D" }}>4Pro by Redspher</span>,
                    all rights reserved.
                </p>
            </div>
        </footer>
    );
}
