import React from "react";
import logoHeader from "../../assets/logo_header_e4p.png";
import { useMediaQuery } from "react-responsive";
export default function Menu() {
  const isMobile = useMediaQuery({ query: `(max-width: 660px)` });
  // dependant on screen size will display different text on button //
  const buttonContent = isMobile ? "Register" : "Register on Easy4Pro";
  return (
    <nav className="menu">
      <a href="https://www.easy4pro.com">
        <img src={logoHeader} alt="Easy4Pro Logo" className="logo-header" />
      </a>
      <a href="https://www.easy4pro.com/contact" target="_blank" rel="noreferrer">
        <button className="register-button">{buttonContent}</button>
      </a>
    </nav>
  );
}
