import React from "react";

export default function KeyDataCard({
    description,
    title,
    icon,
    descriptionArray,
}) {
    const descriptionText = descriptionArray
        ? description.map((item) => {
              return <li key={item}>{item ? item : "-"}</li>;
          })
        : description;
    return (
        //creates cards with an icon, title and description that is passed as props //
        <div className="key-data-card">
            <div className="title-container">
                <img src={icon} alt="" />
                <p className="title">{title}</p>
            </div>
            {title === "Website" ? (
                <p className="description">
                    <a href={`${description}`}>{description}</a>
                </p>
            ) : (
                <ul className="description">
                    {descriptionText.length === 0 ? "-" : descriptionText}
                </ul>
            )}
        </div>
    );
}
