import React, { useState, useEffect } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { useMediaQuery } from "react-responsive";
import geoUrl from "./features.json";
import world from "../../../assets/worldorange.svg";
export default function WorldMapComponent(props) {
    const isTabletorLaptop = useMediaQuery({ query: "(min-width: 700px)" });

    //country data combined in one array (both eu and nonEU), country codes translated from iso2 to iso3 so Map can recognise them //
    const [countries, setcountries] = useState([]);
    const [showAllCountries, setShowAllCountries] = useState(false);
    const getCountryISO3 = require("country-iso-2-to-3");
    const { getName, overwrite } = require("country-list");
    overwrite([
        {
            code: "US",
            name: "USA",
        },
    ]);
    useEffect(() => {
        props.nonEU !== undefined &&
            setcountries([...props.eu, ...props.nonEU]);
        // eslint-disable-next-line
    }, [props.eu, props.nonEU]);

    //set highlited countries on map
    let highlighted = [];
    //if has old system if countries
    if (props.nonEU !== undefined) {
        highlighted = countries.map((country) => {
            return getCountryISO3(country.toUpperCase());
        });
        //if old system doesnt exist
    } else if (props.eu !== undefined) {
        let countries = props.eu;
        highlighted = countries.map((country) => {
            return getCountryISO3(country.toUpperCase());
        });
    }
    const euNames = props.eu.map((country) => {
        return ` ${getName(country).split(",")[0]}`;
    });
    const firstTen = euNames.slice(0, 10);
    const more = euNames.length - 10;

    const nonEUNames =
        props.nonEU &&
        props.nonEU.length >= 2 &&
        props.nonEU.map((country) => {
            return ` ${getName(country).split(",")[0]}`;
        });
    function toggleCountries() {
        setShowAllCountries(!showAllCountries);
    }

    return (
        <div className="world-map">
            <div className="container"></div>
            {/* GENERATES WORLD MAP AND USES HIGHLIGHTED COUNTRIES AS REFERENCE , only renders map if screen is larger than 700px*/}
            {isTabletorLaptop && (
                <ComposableMap projection="geoEqualEarth">
                    <Geographies
                        geography={geoUrl}
                        stroke="#FFF"
                        strokeWidth={0.3}
                    >
                        {({ geographies }) =>
                            geographies.map((geo) => {
                                const isHighlighted =
                                    highlighted.indexOf(geo.id) !== -1;
                                return (
                                    <Geography
                                        key={geo.rsmKey}
                                        geography={geo}
                                        fill={
                                            isHighlighted
                                                ? "#EC6825"
                                                : "#0F2C64"
                                        }
                                    />
                                );
                            })
                        }
                    </Geographies>
                </ComposableMap>
            )}
            <div className="world-map-info">
                <span className="zones">
                    <img src={world} alt="" /> Geographical Zones{" "}
                </span>
                <h2>
                    {showAllCountries ? euNames.toString() : firstTen.toString()}
                    <br />
                    {more >= 1 && (
                        <span
                            onClick={toggleCountries}
                        >{showAllCountries ? "Show Less":` + ${more} others`}</span>
                    )}
                </h2>
                <h3>{nonEUNames && nonEUNames.toString()}</h3>
            </div>
        </div>
    );
}
