import React, { useState, useEffect } from "react";

import searchBlue from "../../../assets/icons/search-blue.svg";
import close from "../../../assets/icons/close-blue.svg";
import truck from "../../../assets/truckblue.svg";

import Results from "../results";
import Filter from "./filter";
import ErrorMessage from "../../notfound/errorMessage";

import axios from "axios";
import url from "../../../config.json";
import geoUrl from "../../../pages/carrier-directory-detail-carrier/worldmap/features.json";

export default function Filters() {
    // set loading of filters on page load
    const [loading, setLoading] = useState(true);

    // initial array with all the carriers
    const [initarray, setInitArray] = useState([]);
    // array with filtered carriers
    const [filteredCarriers, setFilteredCarriers] = useState([]);
    const maxcarriers = initarray.length * 0.9;
    // state to see if too many or no results
    const [tooBroad, setTooBroad] = useState(false);
    const [noResults, setNoResults] = useState(false);

    // arrays of selected filters
    const [tmState, setTmState] = useState([]);
    const [typeState, setTypeState] = useState([]);
    const [countriesState, setCountriesState] = useState([]);
    //saves search input as state
    const [searchInput, setSearchInput] = useState({ search: "" });

    // arrays for displaying filter lists
    const typeArray = [
        { code: "BULK", title: "Bulk" },
        { code: "HAZARDOUS", title: "Hazardous" },
        { code: "LIQUIDS", title: "Liquids" },
        { code: "OVERSIZED", title: "Oversized" },
        { code: "REFRIGERATED", title: "Refrigerated" },
        { code: "STANDARD", title: "Standard" },
        { code: "OTHER", title: "Other" },
    ];
    const tmArray = [
        { code: "MULTIMODAL", title: "Multimodal" },
        { code: "SEAFREIGHT", title: "Sea Groupage" },
        { code: "RAIL", title: "Rail" },
        { code: "FRL", title: "Road FRL" },
        { code: "LTL", title: "Road LTL" },
        { code: "ROAD", title: "Road Premium Freight" },
        { code: "FCL", title: "Sea FCL" },
        { code: "SPECIAL", title: "Special Services" },
        { code: "AIRFREIGHT", title: "Air Freight" },
    ];
    const getCountryISO3 = require("country-iso-2-to-3");
    const { overwrite } = require("country-list");
    overwrite([
        {
            code: "US",
            name: "USA",
        },
    ]);
    const countryArray = geoUrl.objects.world.geometries.map((item) => {
        return { code: item.id, title: item.properties.name };
    });
    // Error handling
    const [error, seterror] = useState(null);

    // Handle select of filter - update relevant array
    const handleFilter = (state, setState) => (event) => {
        if (event.target.checked) {
            setState([...state, event.target.value]);
        } else {
            setState(
                state.filter((filterTag) => filterTag !== event.target.value)
            );
        }
    };
    //watches for change in search input and updates searchInput state
    function handleSearch(event) {
        const { name, value } = event.target;
        setSearchInput({ [name]: value });
        value.length === 0 &&
            typeState.length === 0 &&
            tmState.length === 0 &&
            countriesState.length === 0 &&
            setFilteredCarriers([]);
    }

    // filter functions depending on criteria
    function filterArray(carrierArray, filterTagsArray, criteria) {
        const filteredData = carrierArray.filter((item) =>
            filterTagsArray.length > 0
                ? filterTagsArray.some((filterTag) =>
                      criteria === "transportMode"
                          ? item.transportMode
                                .map((tag) => tag.toUpperCase())
                                .includes(filterTag.toUpperCase())
                          : criteria === "goodsType"
                          ? item.goodsTypes
                                .map((tag) => tag.toUpperCase())
                                .includes(filterTag.toUpperCase())
                          : item.coveredCountries
                                .map((tag) => getCountryISO3(tag.toUpperCase()))
                                .includes(filterTag.toUpperCase())
                  )
                : []
        );
        return filteredData;
    }

    //Filter init array based on searchInput
    function filterSearch() {
        const filteredSearch = initarray.filter((carrier) => {
            return (
                (carrier.group !== undefined &&
                    carrier.group
                        .toUpperCase()
                        .includes(searchInput.search.toUpperCase())) ||
                (carrier.groupName !== undefined &&
                    carrier.groupName
                        .toUpperCase()
                        .includes(searchInput.search.toUpperCase()))
            );
        });
        return filteredSearch;
    }

    function clearFilters() {
        setTmState([]);
        setCountriesState([]);
        setTypeState([]);
        // not very react but the easiest way to do this
        document
            .querySelectorAll("input[type=checkbox]")
            .forEach((el) => (el.checked = false));
    }
    function clearSearch() {
        setSearchInput({ search: "" });
    }
    function clear() {
        clearFilters();
        clearSearch();
    }

    function allFilters() {
        const filteredbycountry = filterArray(
            initarray,
            countriesState,
            "coveredCountries"
        );
        // if all 4
        if (
            typeState.length >= 1 &&
            tmState.length >= 1 &&
            countriesState.length >= 1 &&
            searchInput.search.length >= 1
        ) {
            setFilteredCarriers(
                filterArray(
                    filterArray(
                        filterArray(filterSearch(), typeState, "goodsType"),
                        tmState,
                        "transportMode"
                    ),
                    countriesState,
                    "coveredCountries"
                )
            );
            // if search , type and tm
        } else if (
            typeState.length >= 1 &&
            tmState.length >= 1 &&
            searchInput.search.length >= 1
        ) {
            setFilteredCarriers(
                filterArray(
                    filterArray(filterSearch(), typeState, "goodsType"),
                    tmState,
                    "transportMode"
                )
            );
            // if search, transportmode and countries filters
        } else if (
            tmState.length >= 1 &&
            countriesState.length >= 1 &&
            searchInput.search.length >= 1
        ) {
            setFilteredCarriers(
                filterArray(
                    filterArray(
                        filterSearch(),
                        countriesState,
                        "coveredCountries"
                    ),
                    tmState,
                    "transportMode"
                )
            );
            // if search, goodstype and countries filters
        } else if (
            typeState.length >= 1 &&
            countriesState.length >= 1 &&
            searchInput.search.length >= 1
        ) {
            setFilteredCarriers(
                filterArray(
                    filterArray(
                        filterSearch(),
                        countriesState,
                        "coveredCountries"
                    ),
                    typeState,
                    "goodsType"
                )
            );
            // if search & goods type filter
        } else if (typeState.length >= 1 && searchInput.search.length >= 1) {
            setFilteredCarriers(
                filterArray(filterSearch(), typeState, "goodsType")
            );
            // if search & tm filter
        } else if (tmState.length >= 1 && searchInput.search.length >= 1) {
            setFilteredCarriers(
                filterArray(filterSearch(), tmState, "transportMode")
            );
            // if search & country filter
        } else if (
            countriesState.length >= 1 &&
            searchInput.search.length >= 1
        ) {
            setFilteredCarriers(
                filterArray(filterSearch(), countriesState, "coveredCountries")
            );
        }
        // if all three types of filters
        else if (
            typeState.length >= 1 &&
            tmState.length >= 1 &&
            countriesState.length >= 1
        ) {
            setFilteredCarriers(
                filterArray(
                    filterArray(
                        filterArray(initarray, typeState, "goodsType"),
                        tmState,
                        "transportMode"
                    ),
                    countriesState,
                    "coveredCountries"
                )
            );
            // if goodstype and transportmode filters
        } else if (typeState.length >= 1 && tmState.length >= 1) {
            setFilteredCarriers(
                filterArray(
                    filterArray(initarray, typeState, "goodsType"),
                    tmState,
                    "transportMode"
                )
            );
            // if transportmode and countries filters
        } else if (tmState.length >= 1 && countriesState.length >= 1) {
            setFilteredCarriers(
                filterArray(filteredbycountry, tmState, "transportMode")
            );
            // if goodstype and countries filters
        } else if (typeState.length >= 1 && countriesState.length >= 1) {
            setFilteredCarriers(
                filterArray(filteredbycountry, typeState, "goodsType")
            );
            // if goods type filter
        } else if (typeState.length >= 1) {
            setFilteredCarriers(filterArray(initarray, typeState, "goodsType"));
            // if tm filter
        } else if (tmState.length >= 1) {
            setFilteredCarriers(
                filterArray(initarray, tmState, "transportMode")
            );
            // if country filter
        } else if (countriesState.length >= 1) {
            setFilteredCarriers(
                filterArray(initarray, countriesState, "coveredCountries")
            );
            // if no filter
        } else if (searchInput.search.length >= 1) {
            setFilteredCarriers(filterSearch());
        } else {
            setFilteredCarriers([]);
        }
    }

    // on change of selected filters run filter function
    useEffect(() => {
        allFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tmState, typeState, countriesState]);

    // on change of filteredCarriers check whether there are too many results or no results
    useEffect(() => {
        if (
            (countriesState.length >= 1 ||
                tmState.length >= 1 ||
                typeState.length >= 1 ||
                searchInput.search.length >= 1) &&
            filteredCarriers.length === 0
        ) {
            setNoResults(true);
            setTooBroad(false);
        } else if (
            (countriesState.length >= 1 ||
                tmState.length >= 1 ||
                typeState.length >= 1 ||
                searchInput.search.length >= 1) &&
            filteredCarriers.length >= maxcarriers
        ) {
            setTooBroad(true);
            setNoResults(false);
        } else {
            setTooBroad(false);
            setNoResults(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredCarriers]);

    // On page load get all carriers
    useEffect(() => {
        const getCarriers = async () => {
            try {
                const result = await axios.get(url.api_url + "filter/");
                setInitArray(result.data);
                seterror(null);
                setLoading(false);
            } catch (err) {
                seterror(err);
                setInitArray("");
                console.log(error);
            }
        };
        getCarriers();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        allFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchInput]);
    return (
        <>
            <h2> Are you looking for a Carrier?</h2>

            {loading ? (
                <div className="loading">
                    <img src={truck} alt="" className="truck"></img>
                </div>
            ) : (
                <div className="container">
                    <div className="search-container">
                        <div className="search">
                            <input
                                type="text"
                                className="search-input"
                                id="header-search"
                                placeholder="Search a Carrier by name..."
                                name="search"
                                value={searchInput.search}
                                onChange={handleSearch}
                                maxLength="30"
                            />
                            <img src={searchBlue} alt="" />
                        </div>
                    </div>

                    <div className="filters-container">
                        <span>Search by filters</span>
                        <div className="container">
                            <Filter
                                title="Transport Modes"
                                list={tmArray}
                                setFilterFunction={handleFilter(
                                    tmState,
                                    setTmState
                                )}
                                selected={tmState}
                            />
                            <Filter
                                title="Type of goods"
                                list={typeArray}
                                setFilterFunction={handleFilter(
                                    typeState,
                                    setTypeState
                                )}
                                selected={typeState}
                            />
                            <Filter
                                title="Countries"
                                list={countryArray.sort(function (a, b) {
                                    if (a.title < b.title) {
                                        return -1;
                                    }
                                    if (a.title > b.title) {
                                        return 1;
                                    }
                                    return 0;
                                })}
                                setFilterFunction={handleFilter(
                                    countriesState,
                                    setCountriesState
                                )}
                                selected={countriesState}
                            />
                        </div>
                    </div>
                    {(countriesState.length >= 1 ||
                        tmState.length >= 1 ||
                        typeState.length >= 1 ||
                        searchInput.search.length >= 1) && (
                        <button className="button-tertiary" onClick={clear}>
                            <img src={close} alt="" /> Clear all
                        </button>
                    )}
                </div>
            )}
            {(tooBroad || noResults) && (
                <ErrorMessage tooBroad={tooBroad} noResults={noResults} />
            )}
            <Results
                carrierArray={filteredCarriers}
                tooBroad={tooBroad}
                key="res"
            />
        </>
    );
}
