import React from "react";
export default function NotFound() {
  return (
    <>
      <div className="error-container">
        <h1>
          404
        </h1>
        <p>The page you are looking for is not found</p>
        <a href="/">Return to home</a>
      </div>
    </>
  );
}
