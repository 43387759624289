import React from "react";
import truck from "../../assets/icons/truck-blue.svg";
import boat from "../../assets/icons/boat-blue.svg";
import plane from "../../assets/icons/plane-blue.svg";
import arrow from "../../assets/icons/arrowRight-orange.svg";

export default function Card({ name, id }) {
    return (
        <div className="card-container">
            <h4>{name ? name : id}</h4>
            <div className="icon-container">
                <img src={truck} alt="" />
                <img src={boat} alt="" />
                <img src={plane} alt="" />
            </div>
            <a className="info-container" href={`../carrier/${id}`}  target="_blank" rel="noreferrer">
                <img src={arrow} alt="" />
                <p>More Information</p>
            </a>
        </div>
    );
}
