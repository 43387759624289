import React, { useEffect, useState } from "react";
import axios from "axios";
import url from "../../config.json";
import { useParams } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Error from "../../components/notfound/error";
import arrowleft from "../../assets/arrowleft.svg";
// replace icons for better quality ?//
import truckblue from "../../assets/truckblue.svg";
import planeblue from "../../assets/planeblue.svg";
import shipblue from "../../assets/shipblue.svg";

//  key data card icons //
import icon from "../../assets/contactorange.svg";
import websiteicon from "../../assets/websiteorange.svg";
import legalicon from "../../assets/legalorange.svg";
import truckicon from "../../assets/truckorange.svg";
import transporticon from "../../assets/planeorange.svg";
import goodsicon from "../../assets/goodsorange.svg";
// small key data icon//
import trackingicon from "../../assets/trackingorange.svg";
import certifiedicon from "../../assets/certifiedorange.svg";

import KeyDataCard from "./key-data-card";
import WorldMap from "./worldmap/worldMap";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
export default function CarrierDetail() {
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const ID = id.toUpperCase();
    // gets id from url
    const [userInfo, setUserInfo] = useState([]);
    const [error, seterror] = useState(null);

    const getData = async () => {
        try {
            const result = await axios.get(url.api_url + ID);
            setUserInfo(result.data);
            setLoading(false);
            seterror(null);
        } catch (err) {
            seterror(err);
        }
    };
    // eslint-disable-next-line
    useEffect(() => {
        // eslint-disable-next-line
        getData();
        // eslint-disable-next-line
    }, []);

    const carrierInfoOne = [
        {
            icon: icon,
            title: "Contact",
            description: (
                <>
                    {userInfo.representativeName}
                    <br />
                    {userInfo.representativePhone}
                    <br />
                    {userInfo.representativeEmail}
                </>
            ),
        },
        {
            icon: websiteicon,
            title: "Website",
            description: userInfo.companyWebsite,
        },
    ];
    const carrierInfoTwo = [
        {
            icon: transporticon,
            title: "Transportmode",
            description: userInfo.transportMode,
        },
        { icon: legalicon, title: "Legal", description: "" },
        {
            icon: truckicon,
            title: "Vehicle Type",
            description: userInfo.roadVehicles,
        },
        {
            icon: goodsicon,
            title: "Type of Goods",
            description: userInfo.goodsTypes,
        },
    ];

    //maps over data and passes down properties to create individual cards //
    //checks that data exists before creating card //
    const dataCardsOne = carrierInfoOne.map((data) => {
        return (
            data.description && (
                <KeyDataCard
                    key={data.title}
                    icon={data.icon}
                    title={data.title}
                    description={data.description}
                    descriptionArray={false}
                />
            )
        );
    });
    const dataCardsTwo = carrierInfoTwo.map((data) => {
        return (
            data.description && (
                <KeyDataCard
                    key={data.title}
                    icon={data.icon}
                    title={data.title}
                    description={data.description}
                    descriptionArray={true}
                />
            )
        );
    });
    const groupName = userInfo.groupName;
    const group =
        userInfo.carrierGroup && userInfo.carrierGroup.replaceAll("_", " ");

    //returns carrier page if there is data, else renders 404 error component
    return !error ? (
        <HelmetProvider>
            <Helmet>
                <title>{`Easy4Pro | ${
                    userInfo.carrierGroup && groupName ? groupName : group
                }`}</title>
                <meta
                    name="description"
                    content={`${
                        userInfo.carrierGroup && groupName ? groupName : group
                    }, a Carrier with Easy4Pro - Transport Management Platform`}
                />
            </Helmet>
            <Header />
            {loading ? (
                <div className="loading">
                    <img src={truckblue} alt="" className="truck"></img>
                </div>
            ) : (
                <div className="carrier-detail-container">
                    <span className="return">
                        <img src={arrowleft} alt="" />
                        <a href="/">Go back to homepage</a>
                    </span>
                    <div className="carrier-info">
                        <h1 className="title">
                            {userInfo.carrierGroup && groupName
                                ? groupName
                                : group}
                        </h1>
                        <p>
                            {userInfo.companyDescription
                                ? userInfo.companyDescription
                                : ""}
                        </p>
                        <div className="icons">
                            <img src={truckblue} alt="truck icon" />
                            <img src={planeblue} alt="plane icon" />
                            <img src={shipblue} alt="ship icon" />
                        </div>
                    </div>
                    <div className="key-data-container">
                        <div className="data-container-one data-container">
                            {dataCardsOne}
                        </div>
                        <div className="data-container-two data-container">
                            {dataCardsTwo}
                        </div>
                    </div>
                    <div className="world-map-container">
                        <WorldMap
                            eu={userInfo.coveredCountries}
                            nonEU={userInfo.outEuropeCountries}
                            total={userInfo.coveredCountries.length}
                        />
                    </div>
                    <div className="key-data-small">
                        <KeyDataCard
                            icon={truckicon}
                            title={"Vehicles In Fleet"}
                            description={userInfo.vehicleNumber}
                            descriptionArray={false}
                        />
                        <KeyDataCard
                            icon={trackingicon}
                            title={"Tracking Systems"}
                            description={userInfo.trackingSystems}
                            descriptionArray={true}
                        />
                        <KeyDataCard
                            icon={certifiedicon}
                            title={"Certifications"}
                            description={userInfo.certifications}
                            descriptionArray={true}
                        />
                    </div>
                    <div className="empty-container"></div>
                    <Footer />
                </div>
            )}
        </HelmetProvider>
    ) : (
        <>
            <Error />
            <Footer />
        </>
    );
}
