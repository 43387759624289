import React from "react";
import { Route, Routes } from "react-router-dom";
import CarrierDirectory from "../pages/carrier-directory/carrier-directory";
import CarrierDetail from "../pages/carrier-directory-detail-carrier/detail-carrier";
import Error from "../components/notfound/error";

const createRoutes = () => (
  <Routes>
    <Route path="/" element={<CarrierDirectory />} />
    <Route path="carrier/:id" element={<CarrierDetail key="detail"/>} />
    <Route path="*" element={<Error />} />
  </Routes>
);

export default createRoutes;
