import React from "react";
import Card from "./card";
export default function Results({ carrierArray, tooBroad }) {
    return (
        <>
            {carrierArray.length >= 1 && !tooBroad && (
                <div className="results-container">
                    {carrierArray.map((carrier, index) => {
                        return (
                            <Card
                                key={index}
                                name={carrier.groupName}
                                id={carrier.group}
                            />
                        );
                    })}
                </div>
            )}
        </>
    );
}
