import React from "react";
import ufo from "../../assets/Illustrations/ufo.svg";
import computer from "../../assets/Illustrations/computer.svg";

export default function errorMessage({ tooBroad, noResults}) {
    return (
        <div className="error-message-container">
            <img src={tooBroad ? ufo : computer} alt="" />
            <div>
                <p className="error-text">
                    {tooBroad
                        ? "Your search appears to be too broad!"
                        : noResults
                        ? "No carriers were found!"
                        : ""}
                </p>
                <p className="error-text">
                    {tooBroad
                        ? "Please, refine your search criteria for better results"
                        : noResults
                        ? "Please, expand your search by utilizing the available filters."
                        : ""}
                </p>
            </div>
        </div>
    );
}
